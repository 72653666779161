@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../jiraaf-ui/styles/fonts.css';
@import '../jiraaf-ui/styles/design-system.css';

body,
html {
    padding: 0;
    margin: 0;
    font-family: 'GeneralSans', sans-serif;
    letter-spacing: 0.025em;
}

.border-below {
    background: linear-gradient(90deg,
            transparent 45%,
            theme('colors.secondary.500') 45%,
            theme('colors.secondary.500') 55%,
            transparent 55%),
        linear-gradient(90deg, transparent 0%, rgb(255, 255, 255) 0%);
    background-size: 100% 2px, 100% 100%;
    background-position: 0% 100%, 0% -5px;
    background-repeat: no-repeat;
}

.border-below-left {
    background: linear-gradient(90deg,
            transparent 0%,
            theme('colors.secondary.500') 0%,
            theme('colors.secondary.500') 4%,
            transparent 4%),
        linear-gradient(90deg, transparent 0%, rgb(255, 255, 255) 0%);
    background-size: 100% 2px, 100% 100%;
    background-position: 0% 100%, 0% -5px;
    background-repeat: no-repeat;
}

html {
    scroll-behavior: smooth;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mobile-menu .mobile-menu-content {
    transition: transform 0.2s ease-in-out;
}

.mobile-menu .bg-blend-overlay {
    transition: opacity 0.1s ease-in-out;
}

.mobile-menu-hidden .mobile-menu-content {
    transform: translate3d(-100%, 0, 0);
}

.mobile-menu-visible .mobile-menu-content {
    transform: translate3d(0, 0, 0);
}

.mobile-menu-hidden .bg-blend-overlay {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
}

.mobile-menu-visible .bg-blend-overlay {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.clipping {
    clip-path: ellipse(75% 100% at 50% 0%);
}

@media (max-width: 1024px) {
    .clipping {
        clip-path: ellipse(100% 95% at 50% 0%);
    }
}

@media (min-width: 1025px) and (max-width: 1560px) {
    .clipping {
        clip-path: ellipse(80% 95% at 50% 0%);
    }
}

.focus-gradient {
    background: linear-gradient(270.13deg,
            theme('colors.skyBlue.100') -1.08%,
            theme('colors.skyBlue.50') 99.89%);
    box-shadow: inset 0px -1px 0px theme('colors.gray.100');
}

.header-username {
    max-width: 200px;
}

.flag-container > .selected-flag{
    display: none;
}

.country-list-container::-webkit-scrollbar {
    -webkit-appearance: none;
}

.country-list-container::-webkit-scrollbar:vertical {
    width: 12px;
}

.country-list-container::-webkit-scrollbar:horizontal {
    height: 12px;
}

.country-list-container::-webkit-scrollbar-thumb {
    background-color: theme('colors.primary.500');
    border-radius: 10px;
    border: 2px solid theme('colors.basicWhite');
}

.country-list-container::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: theme('colors.basicWhite');
}

.country-list-container {
    scrollbar-color: theme('colors.primary.500') theme('colors.basicWhite');
    scrollbar-width: thin;
}

.drop-down-item {
    @apply flex p-2 font-light;
}

.dropdown-icon {
    background-image: url('/images/dropdown.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
}

@layer utilities {
    .striped .grid:nth-child(even) {
        @apply bg-gray-50;
    }
}

[data-column-id].rdt_TableCol {
    background-color: theme('colors.gray.50');
}

.rdt_TableRow:nth-child(2n) {
    background-color: theme('colors.gray.50') !important;
}

.rdt_TableRow:hover {
    background-color: theme('colors.gray.50') !important;
}

input[type='radio'].radio-large {
    transform: scale(1.5);
}

.login-grid {
    margin: auto;
}

.login-grid li {
    grid-column: span 2;
    max-width: 500px;
}

@media (min-width: 768px) and (max-width: 1279px) {
    .login-grid li:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
    }
}

@media (min-width: 1279px) {
    .login-grid li {
        grid-column: span 1;
    }
}

[type]:focus {
    @apply !border-primary-500 !ring-primary-500;
}

.refer-and-earn-amount-gradient{
    background: linear-gradient(234deg, theme('colors.secondary.500') 0%, theme('colors.primary.500') 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.badge-border-warning-base{
    @apply border-semantic-warning-base
}

.badge-border-success-base{
    @apply border-semantic-success-base
}

.badge-border-info-base{
    @apply border-semantic-info-base
}